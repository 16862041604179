/*
	HTML5 Reset :: style.css
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%;
}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:
#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll;
}

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

/*
=====---- BOOTSTRAP OVERIDES
*/
/*/
===------ ELEMENTS SIZES -------===
*/
@font-face {
  font-family: "Averta";
  src: url("../fonts/averta.woff2") format("woff2"), url("../fonts/averta.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Averta Bold";
  src: url("../fonts/averta-bold.woff2") format("woff2"), url("../fonts/averta-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
:root {
  --game-fonts-main: "Averta", sans-serif;
  --game-fonts-medium: "Averta Bold", sans-serif;
  --game-font-size-main: 24px;
  --game-line-height-main: 27px;
  --game-color-main: #222222;
  --game-color-light: #e8e8e6;
  --game-background-color-main: #e8e8e6;
  --game-background-color-medium: #cfdadf;
  --game-background-color-hover: #dadada;
  --game-background-color-less-dark: #414141;
  --game-background-color-dark: #353535;
  --game-background-color-greenish: #abc8bd;
  --game-color-wrong: #ff5c35;
  --game-color-right: #00ac69;
  --game-color-congrats: #a5888e;
}

.dnd-game-map-wrapper {
  background-color: var(--game-background-color-main);
}
.dnd-game-map-wrapper .game-wrapper {
  min-height: 100vh;
  color: var(--game-color-main);
  background-color: var(--game-background-color-main);
  font-family: var(--game-fonts-main);
  font-size: var(--game-font-size-main);
  line-height: var(--game-line-height-main);
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.dnd-game-map-wrapper .game-wrapper .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--game-color-light);
  border-radius: 43px;
  margin-left: 4px;
  margin-right: 4px;
  height: 640px;
}
@media only screen and (min-width: 550px) {
  .dnd-game-map-wrapper .game-wrapper .intro {
    height: 715px;
  }
}
.dnd-game-map-wrapper .game-wrapper .intro.hide-intro {
  display: none;
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents {
  margin-left: 15px;
  margin-right: 15px;
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-text {
  max-width: 600px;
  padding-top: 115px;
}
@media only screen and (min-width: 400px) {
  .dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-text {
    padding-bottom: 20px;
  }
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-text p {
  margin-bottom: 12px;
  font-size: 21px;
  line-height: 24px;
}
@media only screen and (min-width: 550px) {
  .dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-text p {
    font-family: var(--game-fonts-main);
    font-size: var(--game-font-size-main);
  }
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-text p.intro-text-light {
  color: #878787;
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-text-small {
  font-size: 20px !important;
  line-height: 24px !important;
  padding-bottom: 24px;
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-image {
  padding-bottom: 20px;
}
@media only screen and (min-width: 400px) {
  .dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-image {
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 550px) {
  .dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-image {
    padding-bottom: 85px;
  }
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .intro-image img {
  max-width: 608px;
  width: 100%;
  height: auto;
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .hit-play {
  border: none;
  background-color: var(--game-background-color-dark);
  border-radius: 50px;
  color: white;
  width: 190px;
  height: 65px;
  font-family: var(--game-fonts-main);
  font-size: 21px;
}
@media only screen and (min-width: 550px) {
  .dnd-game-map-wrapper .game-wrapper .intro .intro-contents .hit-play {
    width: 230px;
    height: 75px;
    font-size: var(--game-font-size-main);
  }
}
.dnd-game-map-wrapper .game-wrapper .intro .intro-contents .hit-play:hover {
  background-color: var(--game-background-color-less-dark);
}
.dnd-game-map-wrapper .game-wrapper .gameplay {
  display: none;
  background-color: var(--game-background-color-main);
}
.dnd-game-map-wrapper .game-wrapper .gameplay.active {
  display: block;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.dnd-game-map-wrapper .game-wrapper .gameplay .gameplay-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
}
.dnd-game-map-wrapper .game-wrapper .order-titles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 360px;
  margin-bottom: 3px;
}
@media only screen and (min-width: 380px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles {
    max-width: 380px;
  }
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles {
    max-width: 540px;
    margin-bottom: 4px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles {
    max-width: 735px;
  }
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles {
    max-width: 1024px;
    margin-bottom: 5px;
  }
}
.dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper {
  -webkit-box-flex: 1.16;
      -ms-flex: 1.16;
          flex: 1.16;
}
@media only screen and (min-width: 380px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper {
    -webkit-box-flex: 1.28;
        -ms-flex: 1.28;
            flex: 1.28;
  }
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper {
    -webkit-box-flex: 1.55;
        -ms-flex: 1.55;
            flex: 1.55;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper {
    -webkit-box-flex: 1.95;
        -ms-flex: 1.95;
            flex: 1.95;
  }
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper {
    -webkit-box-flex: 2.99;
        -ms-flex: 2.99;
            flex: 2.99;
  }
}
.dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper .drag-column {
  padding-left: 25px;
  padding-top: 12px;
  padding-bottom: 15px;
  border-radius: 43px;
  font-family: var(--game-fonts-main);
  font-size: 20px;
  line-height: 30px;
  background-color: var(--game-background-color-medium);
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper .drag-column {
    font-size: 25px;
    line-height: 35px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper .drag-column {
    padding-top: 15px;
    padding-bottom: 17px;
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drag-column-wrapper .drag-column {
    font-size: 38px;
    line-height: 49px;
  }
}
.dnd-game-map-wrapper .game-wrapper .order-titles .drop-column-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.dnd-game-map-wrapper .game-wrapper .order-titles .drop-column-wrapper .drop-column {
  padding-left: 25px;
  padding-top: 12px;
  padding-bottom: 15px;
  margin-left: 3px;
  border-radius: 43px;
  font-family: var(--game-fonts-main);
  font-size: 20px;
  line-height: 30px;
  background-color: var(--game-background-color-medium);
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drop-column-wrapper .drop-column {
    font-size: 25px;
    line-height: 35px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drop-column-wrapper .drop-column {
    padding-top: 15px;
    padding-bottom: 17px;
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .order-titles .drop-column-wrapper .drop-column {
    font-size: 38px;
    line-height: 49px;
    margin-left: 4px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media only screen and (max-width: 539px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .map-container {
  height: 100%;
  background-color: var(--game-background-color-medium);
  width: 100%;
  position: absolute;
  z-index: 0;
  border-radius: 43px;
  width: 450px;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .map-container {
    width: 350px;
  }
}
@media only screen and (max-width: 580px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .map-container {
    width: 320px;
  }
}
@media only screen and (max-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .map-container {
    display: none;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 3px;
  -webkit-column-gap: 3px;
     -moz-column-gap: 3px;
          column-gap: 3px;
  background-color: var(--game-background-color-medium);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 43px;
  margin-right: 3px;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-right: 4px;
    row-gap: 4px;
    -webkit-column-gap: 4px;
       -moz-column-gap: 4px;
            column-gap: 4px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 5px;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: center;
      align-self: center;
  background-color: var(--game-background-color-medium);
  height: 70px;
  width: 70px;
  border-radius: 12px;
  position: relative;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin {
    height: 100px;
    width: 100px;
  }
}
@media only screen and (min-width: 1028px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin {
    height: 134px;
    width: 134px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable {
  cursor: -webkit-grab;
  cursor: grab;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable.while-touched {
  height: 95px;
  width: 95px;
  z-index: 999;
  opacity: 0.5;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable.while-touched {
    height: 134px;
    width: 134px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable:hover {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  z-index: 20;
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable:hover {
    -webkit-transform: scale(1.12);
        -ms-transform: scale(1.12);
            transform: scale(1.12);
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable.dragged {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.3;
  cursor: default;
  outline: 43px solid rgba(23, 138, 94, 0.65) !important;
  outline-offset: -43px;
  overflow: hidden;
  height: 95px;
  width: 95px;
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable.dragged {
    outline: 50px solid rgba(23, 138, 94, 0.65) !important;
    outline-offset: -50px;
    height: 134px;
    width: 134px;
  }
}
@media only screen and (max-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable.dragged {
    height: 70px;
    width: 70px;
    outline: 36px solid rgba(23, 138, 94, 0.65) !important;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .draggable.dragged:hover {
  pointer-events: none;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .enlarge {
  position: absolute;
  left: 50%;
  bottom: 0px;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 20;
  background-color: #e8e8e6;
  width: 90%;
  border-radius: 11px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .enlarge span {
  font-size: 12px;
  line-height: 14px;
  padding: 2px 0 2px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  word-break: break-word;
}
@media only screen and (max-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .enlarge span {
    font-size: 10px;
    line-height: 12px;
    padding: 2px 0 2px 4px;
  }
}
@media only screen and (max-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .enlarge {
    border-radius: 8px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .enlarge.hide-enlarge {
  display: none;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .completed {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 20;
  width: 35px;
  height: 35px;
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .completed {
    width: 48px;
    height: 48px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .completed svg {
  width: 35px;
  height: 35px;
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .completed svg {
    width: 48px;
    height: 48px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin .completed.show-completed {
  display: block;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .draggable-elements .coin.dragged-coin {
  background-color: transparent;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  row-gap: 3px;
  padding: 0;
  background-color: var(--game-background-color-medium);
  border-radius: 43px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .map-picture {
  width: 450px;
  overflow: hidden;
  border-radius: 43px;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .map-picture {
    width: 350px;
  }
}
@media only screen and (max-width: 580px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .map-picture {
    width: 320px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .map-picture img {
  scale: 1.2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .map-title {
  position: absolute;
  top: 6.9%;
  left: 48.4%;
  font-size: 31px;
  line-height: 36px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .map-subtitle1 {
  position: absolute;
  top: 19%;
  left: 2%;
  font-size: 15px;
  line-height: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 5;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .map-subtitle2 {
  position: absolute;
  top: 61%;
  left: 55%;
  font-size: 15px;
  line-height: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 5;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable01 {
  top: 60%;
  left: 15.5%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable01 .area-title {
  margin-bottom: 30px;
  margin-left: 40px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable02 {
  top: 61.5%;
  left: 42%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable02 .area-title {
  margin-bottom: 2px;
  margin-right: 60px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable03 {
  top: 49%;
  left: 13%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable03 .area-title {
  margin-bottom: 25px;
  margin-right: 35px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable04 {
  top: 51%;
  left: 11.5%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable04 .area-title {
  margin-top: 5px;
  margin-left: 95px;
  text-align: right;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable05 {
  top: 47%;
  left: 38%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable05 .area-title {
  margin-bottom: 1px;
  margin-left: 70px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable06 {
  top: 45%;
  left: 19%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable06 .area-title {
  margin-bottom: 45px;
  margin-left: 52px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable07 {
  top: 40%;
  left: 17%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable07 .area-title {
  margin-bottom: 30px;
  margin-right: 5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable08 {
  top: 30%;
  left: 20%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable08 .area-title {
  margin-bottom: 30px;
  margin-left: 20px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable09 {
  top: 30.3%;
  left: 37%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable09 .area-title {
  margin-bottom: 30px;
  margin-left: 20px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable10 {
  top: 34%;
  left: 39%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable10 .area-title {
  margin-bottom: 25px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable11 {
  top: 42%;
  left: 50%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable11 .area-title {
  margin-left: 55px;
  margin-bottom: 1px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable12 {
  top: 45%;
  left: 55%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable12 .area-title {
  margin-bottom: 5px;
  margin-right: 67px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable13 {
  top: 34.7%;
  left: 67.6%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable13 .area-title {
  margin-bottom: 30px;
  margin-left: 90px;
  white-space: nowrap;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable14 {
  top: 37.8%;
  left: 38%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable14 .area-title {
  margin-bottom: 25px;
  margin-left: 10px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable15 {
  top: 40%;
  left: 41%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable15 .area-title {
  margin-bottom: 5px;
  margin-left: 105px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable16 {
  top: 45.5%;
  left: 60%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable16 .area-title {
  margin-bottom: 1px;
  margin-left: 70px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable17 {
  top: 50.5%;
  left: 39%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable17 .area-title {
  margin-bottom: 1px;
  margin-left: 60px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable18 {
  top: 57%;
  left: 85%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable18 .area-title {
  margin-bottom: 30px;
  margin-left: 1px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable19 {
  top: 54%;
  left: 15%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable19 .area-title {
  margin-bottom: 1px;
  margin-left: 50px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable20 {
  top: 54.5%;
  left: 67.5%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable20 .area-title {
  margin-bottom: 25px;
  margin-left: 10px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable21 {
  top: 48.5%;
  left: 24%;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable21 .area-title {
  margin-bottom: 27px;
  margin-left: 15px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: black;
  border-radius: 50%;
  -webkit-transition: border-width 0.2s, background-color 0.4s, -webkit-transform 0.2s;
  transition: border-width 0.2s, background-color 0.4s, -webkit-transform 0.2s;
  transition: border-width 0.2s, transform 0.2s, background-color 0.4s;
  transition: border-width 0.2s, transform 0.2s, background-color 0.4s, -webkit-transform 0.2s;
  width: 10px;
  height: 10px;
  z-index: 2;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .area-title {
  font-size: 12px;
  line-height: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media only screen and (max-width: 580px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .area-title {
    font-size: 10px;
    line-height: 12px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .circle .round-shape {
  position: absolute;
  z-index: 21;
  width: 30px;
  height: 30px;
  top: -15px;
  left: -36px;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .circle .round-shape {
    width: 25px;
    height: 25px;
    top: 2px;
    left: -31px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .circle .round-shape {
    width: 32px;
    height: 32px;
    top: -2px;
    left: -45px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .circle .round-shape circle {
  fill: var(--game-background-color-medium);
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .circle .checkmark-icon {
  display: none;
  position: absolute;
  z-index: 21;
  width: 30px;
  height: 30px;
  top: -15px;
  left: -36px;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .circle .checkmark-icon {
    width: 25px;
    height: 25px;
    top: 2px;
    left: -31px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable .circle .checkmark-icon {
    width: 32px;
    height: 32px;
    top: -2px;
    left: -45px;
  }
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.droppable-hover {
  border-width: 5px;
  -webkit-transform: scale(1.15);
      -ms-transform: scale(1.15);
          transform: scale(1.15);
  background-color: white;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.droppable-hover .area-title {
  color: white;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.droppable-hover::before {
  content: "";
  position: absolute;
  width: 59px;
  height: 59px;
  background-color: #ffc940;
  border-radius: 50%;
  opacity: 0.7;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped {
  width: 12px;
  height: 12px;
  color: var(--game-background-color-main);
  border: 2px solid white;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped::before {
  content: "";
  background-image: var(--dropped-image-path);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: -20px;
  top: 0;
  z-index: -1;
  width: 25px;
  height: 25px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped img {
  display: none;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped .circle .round-shape circle {
  fill: var(--game-background-color-greenish);
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped .circle .checkmark-icon {
  display: block;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable-hover {
  border-width: inherit;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable-hover:hover {
  cursor: not-allowed;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable02::before {
  left: 25px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable03::before {
  left: 25px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable05::before {
  top: -18px;
  left: -5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable07::before {
  top: 10px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable08::before {
  top: 10px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable11::before {
  top: 5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable12::before {
  top: 25px;
  left: 5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable14::before {
  left: 25px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable15::before {
  top: 5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable16::before {
  top: 25px;
  left: 15px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable17::before {
  top: 25px;
  left: 5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable18::before {
  top: 25px;
  left: 5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable19::before {
  top: 10px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable20::before {
  top: 25px;
  left: 5px;
}
.dnd-game-map-wrapper .game-wrapper .drag-and-drop .droppable-elements .droppable.dropped.droppable21::before {
  top: 10px;
  left: 25px;
}
.dnd-game-map-wrapper .game-wrapper #myBtn,
.dnd-game-map-wrapper .game-wrapper #my2ndBtn {
  display: none;
}
.dnd-game-map-wrapper .game-wrapper .modal {
  display: none;
  position: absolute;
  z-index: 22;
  left: 0;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  border-radius: 30px;
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content {
  position: relative;
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 140px;
  margin-left: auto;
  margin-right: auto;
  width: 310px;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper {
    top: 100px;
    width: 500px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper {
    top: 180px;
    width: 524px;
  }
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper img {
  display: block;
  max-width: 100%;
  width: 128px;
  height: 128px;
  -ms-flex-item-align: center;
      align-self: center;
  z-index: 23;
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper .message-title {
  font-family: var(--game-fonts-main);
  color: var(--game-color-main);
  font-size: 24px;
  line-height: 30px;
  background-color: var(--game-color-wrong);
  height: 177px;
  margin-top: -37px;
  padding-top: 65px;
  border-radius: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper .message-title {
    padding-top: 75px;
  }
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper .message-title.correct-answer {
  background-color: var(--game-color-right);
  padding-top: 50px;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper .message-title.correct-answer {
    padding-top: 60px;
  }
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper .message-title.congrats {
  height: 214px;
  background-color: var(--game-color-congrats);
  padding-top: 60px;
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper .message-title .message-content {
  padding-top: 10px;
  color: var(--game-background-color-medium);
  width: 280px;
  font-size: 18px;
  line-height: 21px;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .message-content-wrapper .message-title .message-content {
    width: 460px;
  }
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .close {
  position: absolute;
  left: 0;
  right: 0;
  top: 418px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 270px;
  height: 75px;
  border-radius: 38px;
  border: none;
  color: var(--game-color-main);
  background-color: var(--game-background-color-medium);
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .close {
    top: 378px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .close {
    top: 458px;
  }
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .close .button-text {
  font-family: var(--game-fonts-main);
  font-size: 22px;
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .close:hover {
  color: var(--game-color-main);
  background-color: var(--game-background-color-hover);
}
.dnd-game-map-wrapper .game-wrapper .modal .modal-content .close.go-back {
  text-decoration: none;
  width: 330px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 455px;
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .close.go-back {
    top: 415px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .modal .modal-content .close.go-back {
    top: 495px;
  }
}
.dnd-game-map-wrapper .game-wrapper .picture-modal {
  display: none;
  position: absolute;
  z-index: 22;
  left: 0;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  width: 360px;
  height: 673px;
  border-radius: 30px;
}
@media only screen and (min-width: 380px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal {
    width: 380px;
  }
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal {
    width: 540px;
    height: 547px;
    border-radius: 35px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal {
    border-radius: 43px;
    width: 735px;
    height: 728px;
  }
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal {
    width: 1024px;
    height: 715px;
  }
}
.dnd-game-map-wrapper .game-wrapper .picture-modal .picture-modal-content {
  position: relative;
}
.dnd-game-map-wrapper .game-wrapper .picture-modal .picture-modal-content .picture-modal-img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 7px;
  width: 346px;
  height: 657px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 28px;
}
@media only screen and (min-width: 380px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal .picture-modal-content .picture-modal-img {
    width: 360px;
  }
}
@media only screen and (min-width: 540px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal .picture-modal-content .picture-modal-img {
    top: 7px;
    width: 521px;
    height: 532px;
    border-radius: 20px;
  }
}
@media only screen and (min-width: 735px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal .picture-modal-content .picture-modal-img {
    top: 8px;
    width: 717px;
    height: 710px;
    border-radius: 38px;
  }
}
@media only screen and (min-width: 1024px) {
  .dnd-game-map-wrapper .game-wrapper .picture-modal .picture-modal-content .picture-modal-img {
    top: 15px;
    width: 990px;
    height: 684px;
  }
}
.dnd-game-map-wrapper .game-wrapper .picture-modal .picture-modal-content .close-picture-modal {
  display: block;
  z-index: 24;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  -webkit-filter: drop-shadow(1px 1px 5px #000);
          filter: drop-shadow(1px 1px 5px #000);
}
.dnd-game-map-wrapper .game-wrapper .picture-modal.show-enlarged {
  display: block;
}

/* Wobble Horizontal */